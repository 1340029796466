var w = window,
d = document,
e = d.documentElement,
g = d.getElementsByTagName('body')[0],
widthviewport = w.innerWidth || e.clientWidth || g.clientWidth,
heightviewport = w.innerHeight || e.clientHeight || g.clientHeight;

if(widthviewport <= 768){
	if($('.page--restaurant-detail').length > 0){
		$('.header-menu').addClass('black');
	}
}

(function(){
	var navmenu = $('.menu-navbar ul li');
	var btnBurger = $('#btnMenu');

	btnBurger.on('click', function(){
		var $this = $(this);
		var parent = $this.parent();

		if(parent.hasClass('active')){
			parent.removeClass('active');
		}
		else{
			parent.addClass('active');

			if(widthviewport > 768){
				tweenStagger();
			}
		}
	});

	var tweenStagger = function(){
		TweenMax.staggerFrom(navmenu, 1.2, {
			xPercent: 150,
			ease: Elastic.easeOut.config(1, 0.75)
		}, 0.1);
	}
})();

(function(){
	// MENU
	var parent = $('.menu-navbar').attr('data-selected-menu'),
	child = $('.menu-navbar ul li'),
	item = [];

	for(var i = 0, l = child.length;i < l; i++){
		item = $(child[i]);
		if(parent == item.attr('data-value')){
			item.addClass('active');
		}
	}
})();

(function(){
	// when get hash then scroll to anchor
	function gethashpackage(){
	    if(typeof TAG !== 'undefined') {
	      hashVal = TAG
	    } else if(window.location.hash) {
	      var hashVal = window.location.hash.split("#")[1];
	    } 
	    // console.log('get Hash');
	    // console.log($(".accordion-button[data-target='#"+hashVal+"'"));
	    $('.accordion-content').not('#' + hashVal).hide();
	    $('#' + hashVal).slideDown(400);
	    $(".accordion-button[data-target='#"+hashVal+"']").addClass('active');

	    //
	}

	$('.accordion-button').on('click', function(e){
		e.preventDefault();
		var id = $(this).attr('data-target');

		if($(this).hasClass('active')){
			// console.log('has Class');
			$('.accordion-button').removeClass('active');			
			$('.accordion-content').slideUp(400);			
		}
		else{
			// console.log('not has Class');
			$('.accordion-button').removeClass('active');
			$('.accordion-content').not(id).slideUp(400);

			$(this).toggleClass('active');
			$(id).slideToggle(400);

			if(widthviewport > 768){
				setTimeout(function(){
					var position = $(id).offset().top - 100;
					$("body, html").animate({
						scrollTop: position
					}, 400 );
				}, 700);
			}
		}

	});																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																											

	gethashpackage();

})();

(function($){
	$('.collapsible-button').on('click', function(e){
		e.preventDefault();
		// console.log('click');
		var colId = $(this).attr('data-target');
// console.log($(colId));
		$(colId).slideToggle(400);
		$(this).toggleClass('collapse');
	});
})(jQuery);

(function($){
	/* DATE PICKER GLOBAL */
	$('.datepick').datepicker({
		language: 'en',
		dateFormat: 'dd-mm-yyyy',
		navTitles: {days: 'MM <i>yyyy</i>'},
		autoClose: true,
		toggleSelected: false
	});
})(jQuery);